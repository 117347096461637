.container2{
    display: inline-flex;
    background-color: #0b3050;
    width: 100%;
    position: fixed;
    height: auto;
    top: 0px;
    left: 0px;
    z-index: 2;
    padding-bottom: 20px;
    align-items: center;
    justify-content: center;
    
}

.inner-container {
    width: 30px;
    height: 100%; 
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
    cursor: pointer;
    position: fixed;
    margin-top: 3vh;
    margin-right: 3vh;
    top: 0;
    right: 0;
    
}

.inner-container-extras{
    width: 200px; 
    height: 100%; 
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
    font-size: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 3vh;
    
}


.logoContainer{
    cursor: pointer;
    display: flex;
    margin-top: 3vh;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 1vh;
}

.logo {
    height: auto;
    
}



/* Media query for mobile devices */
@media screen and (max-width: 768px) {
    /* Adjust styles for smaller screens */

    .inner-container-extras {
        display: inline-flex; /* Show the extra menu icons on mobile */
       font-size: medium;
    }

    .logo {
        height: auto;
        margin-right: 2vw;
    }
}