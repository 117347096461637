.classTable {
    margin: 20px 0;
}

.title {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.table {
    width: auto;
    border-collapse: collapse;
}

.table th,
.table td {
    padding-right: 5px;
    padding-left: 5px;
    border: 1px solid #ddd;
    text-align: left;
    min-width: 3rem;
}

.table th {
    background-color: #f4f4f4;
}

.message {
    text-align: center;
    font-size: 1.2rem;
    margin: 20px 0;
    font-weight: bold;
}

.editButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s ease;
}

.editButton:hover {
    background-color: #0056b3;
}

/* Show to Student Button Styles */
.showToStudentButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s ease;
}

.showToStudentButton:hover {
    background-color: #0056b3;
}

/* When feedback is visible (button in "ON" state) */
.showToStudentButton.bg-green-500 {
    background-color: #28a745; /* Green */
    cursor: not-allowed; /* Disable cursor interaction */
}

.showToStudentButton.bg-green-500:hover {
    background-color: #28a745; /* Keep the same when hovered */
}

/* Disabled button style */
.showToStudentButton[disabled] {
    background-color: #28a745; /* Same as ON state */
    cursor: not-allowed;
    opacity: 0.6; /* Reduce opacity */
}

/* Optional: Add styles for "OFF" button when not clicked yet */
.showToStudentButton.bg-blue-500 {
    background-color: #007bff; /* Blue */
}

.showToStudentButton.bg-blue-500:hover {
    background-color: #0056b3; /* Darker blue */
}
