.feedback-editor-container {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    background-color: white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    min-height: 40rem;
    margin-top: 16px;
}

.editor-toolbar {
    display: flex;
    margin-bottom: 8px;
}

.toolbar-btn {
    background-color: #f0f0f0;
    border: none;
    padding: 8px;
    margin-right: 4px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.toolbar-btn.active {
    background-color: #007bff;
    color: white;
}

.toolbar-btn:hover {
    background-color: #ddd;
}

.editor-wrapper {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 12px;
    min-height: 30rem;
    cursor: text;
}

.editor-wrapper:focus-within {
    border-color: #007bff;
}

.editor-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
}

.word-count {
    color: #666;
    font-size: 14px;
}

.save-btn {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.save-btn:disabled {
    background-color: #aaa;
    cursor: not-allowed;
}

.save-btn:hover:not(:disabled) {
    background-color: #0056b3;
}
