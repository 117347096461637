body{
    color: black;
    padding-top: 120px;
    background-color: #0b3050;
    justify-content: center;
    align-items: center;

    
}

h1{
    color: white;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    padding-bottom: 100px;
    margin-left: 3vw;
}
h2{
    color: white;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    padding-bottom: 100px;
    margin-left: 3vw;
}

ul{
    color: black;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin-left: 1vw;
    padding-top: 1vw ;
    font-size: large;
}

footer{
    background-color: #082239;
    color: grey;
    height:10vh;
    margin-top:30vh;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
}


.labels{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 5vw;
}
.header{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 5vh;
    color: black;
    margin-top: 10vh;
    margin-bottom: 7vh;
}

.emailBox{
    width: 350px;
    border-radius: 3px;
    border-style: solid;
    border-color: #0b3050;
    height: 35px;
    border-width: 1px;
}
.logButton{
    width: 200px;
    height: 40px;
    background-color: #155893;
    border-radius: 9px;
    color: white;
    border:none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.emailBox:invalid{
    border-color: red;
}

.custom-container{
    background-color: #f3fbfb;
      width: 450px; /* 80% of the viewport height */
      height: 90vh;
      justify-content: center;
      border-radius: 5px;
      display: flex;
      text-align: center;
}

.custom-container2{
    background-color: #f3fbfb;
    width: fit-content;
    padding-top: 40px;
    padding-right: 20px;
    height: 50vh;
    justify-content: center;
    padding-bottom: 30px;
    border-radius: 5px;
    align-items: center;

}

.animated-paragraph{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    max-width: 1100px;
    padding-bottom: 10vh;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #0b3050;
    text-align: center;

 
}

.instruction-container{
    width: 70px;
    border: black;
    border-width: 1vw;
}

.google-button{
    width: 200px;
    height: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.dashboard-button{
    transition: background-color 0.5s ease, color 0.5s ease;

}

.dashboard-button:hover{
    cursor: pointer;
    color: #f3fbfb;
    background-color: #155893;
}   
/*
the box that you place your files in, either browse or drag & drop
*/
#file-upload-box {
    border: 2px dashed #ccc;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    
}


#file-upload-box:hover {
    background-color: grey;
}

.txt{
 font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
 font-size: larger;   
}

th, tr {
    width: 20%;
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    background: #0b3050;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
}

.modal-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-content h2 {
    margin-bottom: 20px;
}

.modal-content p {
    margin-bottom: 20px;
}

.modal-content button {
    margin-top: 20px;
}

.message {
    text-align: center;
    margin-top: 1rem;
}

.message-success {
    color: green;
}

.message-error {
    color: red;
}

/* Media query for mobile devices */
@media screen and (max-width: 768px) {
    .custom-container2{
        margin-left: 0;
        width: 100%;
    }
    .txt{
        font-size: medium;   
       }

    .custom-container{
        width: 400px;
    }   

}
