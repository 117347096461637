/* StudentDashboard.module.css */

/* General container styles */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

/* Grid layout for displaying the assignments */
.assignmentTable {
  margin-top: 20px;
  width: 100%;
}

.assignmentTable table {
  width: 100%;
  border-collapse: collapse;
}

.assignmentTable th,
.assignmentTable td {
  padding: 12px;
  border: 1px solid #ddd;
}

.assignmentTable th {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  text-align: center;
}

.assignmentTable td {
  background-color: #f9f9f9;
  text-align: center;
}

/* Button styles inside the table */
.assignmentTable td button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}

.assignmentTable td button:hover {
  background-color: #45a049;
}

/* Welcome title */
.welcomeTitle {
  font-size: 26px;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;
}

/* Loader style */
.loader {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

/* Notification for showing messages like 'No classes found' */
.notification {
  font-size: 1.2rem;
  font-weight: bold;
  color: red;
  text-align: center;
  margin-top: 20px;
}

/* Modal content */
.modalContent {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  max-width: 90%;
  margin: auto;
}

/* Tooltip button */
.tooltip {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the button */
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
