.dashboard {
    font-family: 'Arial', sans-serif;
    color: #333;
    background: #f9f9f9;
    min-height: 100vh;
    padding: 20px;
    width: 90vw;
    border-radius: 1%;
}

.header {
    background: #0055a5;
    color: #ffffff;
    padding: 10px 20px;
    
}

.content {
    margin: 20px auto;
    max-width: 1200px;
}

.classCard {
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    
}

.loading {
    text-align: center;
    font-size: 18px;
    color: #0066cc;
}

.noClasses {
    text-align: center;
    font-size: 18px;
    color: #ff0000;
}
